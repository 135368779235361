.rootLoadingSpinner {
  height: 5px;
  width: 5px;
  color: #fff;
}
  [data-theme="light"] .rootLoadingSpinner {
    color: #161212;
  }
  .rootLoadingSpinner {

  margin: auto;
  display: flex;
  align-items: center;
  box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
    -10px -10px 0 5px;
  animation: loader-38 3s infinite;
}

@keyframes loader-38 {
  0% {
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
      -10px -10px 0 5px;
  }
  8.33% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px -10px 0 5px,
      10px -10px 0 5px;
  }
  16.66% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      10px 10px 0 5px;
  }
  24.99% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  33.32% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      -10px -10px 0 5px;
  }
  41.65% {
    box-shadow: 10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      10px -10px 0 5px;
  }
  49.98% {
    box-shadow: 10px 10px 0 5px, 10px 10px 0 5px, 10px 10px 0 5px,
      10px 10px 0 5px;
  }
  58.31% {
    box-shadow: -10px 10px 0 5px, -10px 10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  66.64% {
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  74.97% {
    box-shadow: -10px -10px 0 5px, 10px -10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  83.3% {
    box-shadow: -10px -10px 0 5px, 10px 10px 0 5px, 10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  91.63% {
    box-shadow: -10px -10px 0 5px, -10px 10px 0 5px, -10px 10px 0 5px,
      -10px 10px 0 5px;
  }
  100% {
    box-shadow: -10px -10px 0 5px, -10px -10px 0 5px, -10px -10px 0 5px,
      -10px -10px 0 5px;
  }
}

.form-div form {
  position: absolute;
  inset: 4px;
  border-radius: 4px;
  z-index: 2;
  padding: 50px 40px;
}
.form-div {
  position: relative;
  min-width: 300px;
  height: auto;
  width: auto;
  max-height: unset;
  border-radius: 8px;
  overflow: hidden;
  margin: auto;
}
.form-div-signup{
  max-width: 500px;
  min-height: 840px;
}
.form-div-login{
  max-width: 380px;
  min-height: 500px;
}
.form-container {
  min-height: calc(100vh - 58.2px);
  height: auto;
  max-height: unset;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.form-div::before{
    position: absolute;
    top: -50%;
    left: -50%;
    z-index: 1;
    min-width: 300px;
    max-width: 500px;
    height: 800px; 
}
.form-div::after{
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: 1;
  min-width: 300px;
  max-width: 500px;
  height: 800px;
}
[data-theme="light"] .form-container {
    background-image: linear-gradient(
      to top,
      #c4c5c7 0%,
      #dcdddf 52%,
      #ebebeb 100%
    );
  }
[data-theme="dark"] .form-container {
    background-image: linear-gradient(to right, #141e30, #243b55);
  }
[data-theme="light"] .gradient-bg {
    background-image: linear-gradient(-20deg, #616161 0%, #9bc5c3 100%);
  }
[data-theme="dark"] .gradient-bg {
    background: #4b79a1; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #283e51,
      #0f171f
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
/* errors */
.errors {
  position: relative;
  color: red;
  font-weight: 500;
  animation: waviy 2s infinite;
  animation-delay: -1s;
}
/* inputs */
.form-input {
  min-width: 300px;
  width: 100%;
  max-width: unset;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  outline: none;
  border: none;
  transition: background-color 0.3s;
}
/* Focus styles */
.form-input:focus {
    border: none;
    outline: none;
  }
[data-theme="light"] .form-input {
    background-color: white;
  }
[data-theme="dark"] .form-input {
    background-color: #3d404a;
  }
.login-animation::before,
.login-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 200% 200%;
  background-repeat: no-repeat;
  transform-origin: bottom right;
  animation: form-animation 6s linear infinite;
  animation-delay: -1s;
}
.login-animation::before {
  background: linear-gradient(
    0deg,
    transparent,
    transparent,
    #fad932,
    #308853,
    #fad932
  );
}
.login-animation::after {
  background: linear-gradient(
    0deg,
    transparent,
    transparent,
    #9333ea,
    #45f3ff,
    #ffab00
  );
}
@keyframes form-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes waviy {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(20px);
  }
  50% {
    transform: translateX(0); /* Back to the initial position */
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0); /* Back to the initial position */
  }
}

